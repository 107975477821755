

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";
import {watch} from "vue";

@Options({
    components: {Select},
    props: {
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
        value: String,
    },
    beforeMount() {
        this.selected = {
            title: this.$props.value,
            id: this.$props.value,
        }
    },
    data() {
        return {
            list: [],
            selected: {
                title: '',
                id: ''
            }
        }
    },
    mounted() {

        console.log(this.$props.collectedData)
        watch(this.$props, () => {
            this.loadSheets();
        })
        this.loadSheets();
    },
    methods: {
        setSheetId(worksheetId: string): void
        {
            this.$props.parameters?.forEach((param: any) => {
                if (param.name ==='worksheetId') {
                    // value can be empty. it means it uses the default drive id
                    param.value = worksheetId;
                }
            })
            this.$emit('paramValue', worksheetId)
        },
        loadSheets() {

            let spreadsheetId: string | null = null;
            let worksheetId: string | null = null;

            for (const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'spreadsheetId') {
                    spreadsheetId = this.$props.parameters[i].getValue();
                }
            }

            if (spreadsheetId === null || spreadsheetId === '') {
                return '';
            }

            for (const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'worksheetId') {
                    worksheetId = this.$props.parameters[i].getValue();
                }
            }

            this.selected.title = '';
            this.selected.id = '';

            let queryString = '?appAccountId=' + this.appAccountId + '&fileId=' + spreadsheetId;

            ApiRequest('GET', '/google/spreadsheet/worksheets' + queryString).then(async (response: any) => {

                const result = await response.json();

                this.list = [];
                for (const i in result) {
                    this.list.push({
                        id: result[i].id ?? '',
                        title: result[i].name,
                        description: result[i].name
                    })

                    if (parseInt(result[i].id) === parseInt(worksheetId ?? '0')) {
                        this.selected.title = result[i].name;
                        this.selected.id = result[i].id;
                    }
                }
            })
        }
    }
})


export default class GoogleSheetsWorksheetId extends Vue {}

