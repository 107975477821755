

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";

@Options({
    emits: ['paramValue'],
    components: {Select},
    props: {
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
    },
    data() {
        return {
            selected: null,
            list: []
        }
    },
    methods: {
        setDriveId(driveId: string | null): void
        {
            this.$props.parameters?.forEach((param: any) => {
                if (param.name ==='driveId') {
                    // value can be empty. it means it uses the default drive id
                    param.value = driveId;
                }
            })

            this.$emit('paramValue', driveId)
        },
        loadDrives() {

            let driveId = '';

            for (const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'driveId') {
                    driveId = this.$props.parameters[i].getValue();
                }
            }

            ApiRequest('GET', '/google/drive/list?appAccountId=' + this.$props.appAccountId).then(async (response: any) => {

                this.list = [];
                this.selected = '';

                const result = await response.json();

                for (const i in result) {
                    this.list.push({
                        id: result[i].id ?? '',
                        title: result[i].name,
                        description: result[i].name
                    })
                }

                this.selected = driveId;
            });
        }
    },
    mounted() {
        this.loadDrives();
    }
})

export default class GoogleDriveDriveId extends Vue {}

